import { CellContext } from '@tanstack/react-table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../../routes/index.routes';

export const advertiserNameAdxCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.advertisers.view(row.getValue('advertiserId'))}>{row.getValue('advertiser')}</TableLink>;
};
export const campaignNameAdxCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.campaigns.view(row.getValue('campaignId'))}>{row.getValue('campaign')}</TableLink>;
};
export const campaignGroupNameAdxCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.campaignGroups.view(row.getValue('campaignGroupId'))}>{row.getValue('campaignGroup')}</TableLink>;
};
export const domainLinkCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.domains.view(row.getValue('domain'))}>{row.getValue('domain')}</TableLink>;
};
