/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BidderApi } from '../../../apis/bidder.api';
import { constantCase } from 'change-case';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { Statuses, Status } from '../../../enums/status';
import { HttpError } from '../../../models/http-error.model';
import {
	BidderCampaignModel,
	IBidderCampaignCreateForm,
	IBidderCampaignEditForm,
} from '../../../models/bidder-campaign/bidder-camapign.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { runInAction } from 'mobx';

export class BidderCampaignCrudStore extends BaseCrudStore<
	BidderCampaignModel,
	IBidderCampaignCreateForm,
	IBidderCampaignEditForm,
	HttpError
> {
	constructor() {
		super({
			// @ts-ignore
			apiLayer: BidderApi,
			model: BidderCampaignModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getCreateFormData(): IBidderCampaignCreateForm {
		const status = this.getData().getStatus();

		return {
			campaign: {
				campaign_id: this.getData().getId(),
				campaign_daily_budget: this.getData().getDailyBudget(),
				campaign_status: (status ? constantCase(status) : constantCase(Statuses.Paused)) as Status,
			},
		};
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getEditFormData(): IBidderCampaignEditForm {
		const status = this.getData().getStatus();

		return {
			campaign: {
				campaign_id: this.getData().getId(),
				campaign_daily_budget: this.getData().getDailyBudget(),
				campaign_status: (status ? constantCase(status) : constantCase(Statuses.Paused)) as Status,
				campaign_targeting_link_per_publisher: this.getData().getTargetingLinkPerPublisher(),
				campaign_publisher_domain_status_limits: this.getData().getPublisherDomainStatusLimit(),
				campaign_advertiser_id: this.getData().getAdvertiserId(),
				campaign_group_id: this.getData().getCampaignGroupId(),
				campaign_unique_sale: this.getData().getUniqueSale(),
				campaign_max_loss_per_domain: this.getData().getMaxLossPerDomain(),
				campaign_max_clicks_to_first_conv: this.getData().getMaxClicksToFirstConv(),
				campaign_q_max_loss_per_domain: this.getData().getQmaxLossPerDomain(),
				campaign_q_max_clicks_to_first_conv: this.getData().getQmaxClicksToFirstConv(),
				campaign_performance_pixel_type: this.getData().getPerformancePixelType(),
				campaign_jm_exclusion: this.getData().getJmExclusion(),
				campaign_domains: this.getData().getDomains(),
				campaign_targeting: this.getData().getTargeting(),
				campaign_initial_bids: this.getData().getInitialBids(),
				domains_states_distribution: this.getData().getDomainsStatesDistribution(),
				campaign_name: this.getData().getName(),
			},
		};
	}

	public isValid(): boolean {
		this.formStore.reset();

		if (!this.getData().getName()) {
			this.formStore.addError(new FormError('name', 'Please provide a name'));
		}

		return this.formStore.getIsValid();
	}

	public getBidderCampaign(id: id) {
		this.setIsLocalCache(true);
		this.setIsLoading(true);

		return BidderApi.getBidderCampaign(id)
			.then(res => {
				const data = new this.model({ ...res.campaign, id: res.MetaData.campaign_id });
				const originData = new this.model(res);
				runInAction(() => {
					this.setIsLocalCache(false); //TODO: move to finally
					this.setData(data);
					this.setOriginData(originData);
					this.setIsLoading(false);
				});
				return data;
			})
			.catch(error => {
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response?.status }));
					}
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
			});
	}

	enableCampaign(campaignsId: id): Promise<void> {
		return BidderApi.enableCampaign(campaignsId)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseCampaign(campaignsId: id): Promise<void> {
		return BidderApi.pauseCampaign(campaignsId)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}
}
