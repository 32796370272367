import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';
import { DomainListTypes } from '../../enums/bidder-campaign-enum';
import { CampaignTargeting } from './campaign-targeting';

export type TypeCampaignPublisherDomainStatusLimits = {
	pid: id;
	publisher_name: string;
	domain_status_limits: TypeDomainStatusLimit[];
};

export type TypeDomainStatusLimit = {
	id: id;
	domain_status: string;
	limit: number;
};

type TypeCampaignDomains = {
	domains?: string;
	list_type?: DomainListTypes;
};

type TypeCampaignInitialBids = {
	max_loss_per_key: number;
	max_num_of_clicks_to_first_conv: number;
	maximum_bid: number;
	minimal_bid: number;
	random_bid_step: number;
};

type TypeDomainStatusDistribution = {
	domains_quantity: number;
	state_name: string;
};

interface IBidderCampaign {
	id?: id;
	campaign_id?: id;
	campaign_name?: string;
	campaign_advertiser_id?: number;
	campaign_daily_budget?: number;
	campaign_domains?: TypeCampaignDomains;
	campaign_group_id?: number;
	campaign_initial_bids?: TypeCampaignInitialBids;
	campaign_jm_exclusion?: number;
	campaign_max_loss_per_domain?: number;
	campaign_max_clicks_to_first_conv?: number;
	campaign_performance_pixel_type?: string;
	campaign_publisher_domain_status_limits?: TypeCampaignPublisherDomainStatusLimits[];
	campaign_q_max_clicks_to_first_conv?: number;
	campaign_q_max_loss_per_domain?: number;
	campaign_status?: string;
	campaign_targeting_link_per_publisher?: number;
	campaign_targeting?: CampaignTargeting[];
	campaign_unique_sale?: number;
	domains_states_distribution?: TypeDomainStatusDistribution[];
}

export interface IBidderCampaignCreateForm {
	campaign: {
		campaign_id?: id;
		campaign_status?: string;
		campaign_daily_budget?: number;
		campaign_targeting_link_per_publisher?: number;
	};
}

export interface IBidderCampaignEditForm {
	campaign: {
		campaign_id?: id;
		campaign_status?: string;
		campaign_daily_budget?: number;
		campaign_domains?: TypeCampaignDomains;
		campaign_initial_bids?: TypeCampaignInitialBids;
		campaign_jm_exclusion?: number;
		campaign_max_loss_per_domain?: number;
		campaign_max_clicks_to_first_conv?: number;
		campaign_performance_pixel_type?: string;
		campaign_publisher_domain_status_limits?: TypeCampaignPublisherDomainStatusLimits[];
		campaign_q_max_clicks_to_first_conv?: number;
		campaign_q_max_loss_per_domain?: number;
		campaign_targeting?: CampaignTargeting[];
		campaign_targeting_link_per_publisher?: number;
		campaign_unique_sale?: number;
		domains_states_distribution?: TypeDomainStatusDistribution[];
		campaign_group_id?: number;
		campaign_name?: string;
		campaign_advertiser_id?: number;
	};
}

export class BidderCampaignModel implements IBidderCampaign {
	id?: id;
	campaign_id?: id;
	campaign_name?: string;
	campaign_advertiser_id?: number;
	campaign_daily_budget?: number;
	campaign_domains?: TypeCampaignDomains;
	campaign_group_id?: number;
	campaign_initial_bids?: TypeCampaignInitialBids;
	campaign_jm_exclusion?: number;
	campaign_max_loss_per_domain?: number;
	campaign_max_clicks_to_first_conv?: number;
	campaign_performance_pixel_type?: string;
	campaign_publisher_domain_status_limits?: TypeCampaignPublisherDomainStatusLimits[];
	campaign_q_max_clicks_to_first_conv?: number;
	campaign_q_max_loss_per_domain?: number;
	campaign_status?: string;
	campaign_targeting_link_per_publisher?: number;
	campaign_targeting?: CampaignTargeting[];
	campaign_unique_sale?: number;
	domains_states_distribution?: TypeDomainStatusDistribution[];

	constructor(data: IBidderCampaign) {
		this.id = data?.id;
		this.campaign_name = data?.campaign_name;
		this.campaign_advertiser_id = data?.campaign_advertiser_id;
		this.campaign_daily_budget = data?.campaign_daily_budget;
		this.campaign_domains = data?.campaign_domains;
		this.campaign_group_id = data?.campaign_group_id;
		this.campaign_initial_bids = data?.campaign_initial_bids;
		this.campaign_jm_exclusion = data?.campaign_jm_exclusion;
		this.campaign_max_loss_per_domain = data?.campaign_max_loss_per_domain;
		this.campaign_max_clicks_to_first_conv = data?.campaign_max_clicks_to_first_conv;
		this.campaign_performance_pixel_type = data?.campaign_performance_pixel_type;
		this.campaign_publisher_domain_status_limits = data?.campaign_publisher_domain_status_limits;
		this.campaign_q_max_clicks_to_first_conv = data?.campaign_q_max_clicks_to_first_conv;
		this.campaign_q_max_loss_per_domain = data?.campaign_q_max_loss_per_domain;
		this.campaign_status = data?.campaign_status;
		this.campaign_targeting_link_per_publisher = data?.campaign_targeting_link_per_publisher;
		this.campaign_targeting = data?.campaign_targeting?.map(link => new CampaignTargeting(link));
		this.campaign_unique_sale = data?.campaign_unique_sale;
		this.domains_states_distribution = data?.domains_states_distribution;

		makeAutoObservable(this);
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.campaign_name;
	}

	getCampaignGroupId() {
		return this.campaign_group_id;
	}

	getAdvertiserId() {
		return this.campaign_advertiser_id;
	}

	getDailyBudget() {
		return this.campaign_daily_budget;
	}

	getDomains() {
		return this.campaign_domains;
	}

	getInitialBids() {
		return this.campaign_initial_bids;
	}

	getJmExclusion() {
		return this.campaign_jm_exclusion;
	}

	getMaxLossPerDomain() {
		return this.campaign_max_loss_per_domain;
	}

	getMaxClicksToFirstConv() {
		return this.campaign_max_clicks_to_first_conv;
	}

	getPerformancePixelType() {
		return this.campaign_performance_pixel_type;
	}

	getPublisherDomainStatusLimit() {
		return this.campaign_publisher_domain_status_limits;
	}

	getQmaxClicksToFirstConv() {
		return this.campaign_q_max_clicks_to_first_conv;
	}

	getQmaxLossPerDomain() {
		return this.campaign_q_max_loss_per_domain;
	}

	getStatus() {
		return this.campaign_status;
	}

	getTargetingLinkPerPublisher() {
		return this.campaign_targeting_link_per_publisher;
	}

	getTargeting() {
		return this.campaign_targeting;
	}

	getUniqueSale() {
		return this.campaign_unique_sale;
	}

	getDomainsStatesDistribution() {
		return this.domains_states_distribution;
	}

	setId(id: id) {
		this.id = id;
	}

	setName(name: string) {
		this.campaign_name = name;
	}

	setCampaignGroupId(campaignGroupId: number) {
		this.campaign_group_id = campaignGroupId;
	}

	setDailyBudget(daily_budget: number) {
		this.campaign_daily_budget = daily_budget;
	}

	setDomains(domains: TypeCampaignDomains) {
		this.campaign_domains = domains;
	}

	setInitialBids(initialBids: TypeCampaignInitialBids) {
		this.campaign_initial_bids = initialBids;
	}

	setJmExclusion(jmExclusion: number) {
		this.campaign_jm_exclusion = jmExclusion;
	}

	setMaxLossPerDomain(maxLossPerDomain: number) {
		this.campaign_max_loss_per_domain = maxLossPerDomain;
	}

	setMaxClicksToFirstConv(maxClicksToFirstConv: number) {
		this.campaign_max_clicks_to_first_conv = maxClicksToFirstConv;
	}

	setPerformancePixelType(performancePixelType: string) {
		this.campaign_performance_pixel_type = performancePixelType;
	}

	setPublisherDomainStatusLimit(publisherDomainStatusLimit: TypeCampaignPublisherDomainStatusLimits[]) {
		this.campaign_publisher_domain_status_limits = publisherDomainStatusLimit;
	}

	setQmaxClicksToFirstConv(qmaxClicksToFirstConv: number) {
		this.campaign_q_max_clicks_to_first_conv = qmaxClicksToFirstConv;
	}

	setQmaxLossPerDomain(qmaxLossPerDomain: number) {
		this.campaign_q_max_loss_per_domain = qmaxLossPerDomain;
	}

	setStatus(status: string) {
		this.campaign_status = status;
	}

	setTargetingLinkPerPublisher(targetingLinkPerPublisher: number) {
		this.campaign_targeting_link_per_publisher = targetingLinkPerPublisher;
	}

	setTargeting(targeting: CampaignTargeting[]) {
		this.campaign_targeting = targeting;
	}

	setUniqueSale(uniqueSale: number) {
		this.campaign_unique_sale = uniqueSale;
	}

	setDomainsStatesDistribution(domainsStatesDistribution: TypeDomainStatusDistribution[]) {
		this.domains_states_distribution = domainsStatesDistribution;
	}
}
