import { makeAutoObservable } from 'mobx';
import ReportStore from './report.store';
import { IColumnConfig, ReportFilter, ReportParams, ReportSortOrder } from './report.types';
import { OnChangeFn, PaginationState, RowSelectionState, SortingState, VisibilityState } from '@tanstack/react-table';
import { ITableState, TableState } from './table-state.store';

export interface IReportTableStoreParams<T> {
	reportParams: Partial<ReportParams>;
	columns: IColumnConfig<T>[];
	tableState?: Partial<ITableState>;
	isGraphAvailable: boolean;
	tableName: string;
}

export class ReportTableStore<T> {
	private _reportStore: ReportStore<T>;
	private _columns: IColumnConfig<T>[];
	private _tableState: TableState;

	constructor({ reportParams, columns, isGraphAvailable, tableState, tableName }: IReportTableStoreParams<T>) {
		this._columns = columns;

		this._tableState = new TableState({
			...{
				columnsConfig: columns,
				pagination: {
					pageIndex: 0,
					pageSize: 20,
				},
				sorting: reportParams.sortBy
					? [
							{
								id: columns.find(c => c.name === reportParams.sortBy)?.accessor ?? reportParams.sortBy,
								desc: reportParams.sortOrder === ReportSortOrder.Desc,
							},
					  ]
					: [],
				filters: [],
				columnVisibility: this.getDefaultColumnVisibility(),
				rowSelection: {},
			},
			tableName,
			...tableState,
		});

		let groupBys: string[] = this.columns
			.filter(
				col =>
					col.alwaysVisible ||
					col.alwaysHidden ||
					col.type === 'data' ||
					(this.tableState.columnVisibility[col.accessor] && col.type !== 'metrics')
			)
			.reduce((acc, col) => {
				acc.push(col.name, ...(col.linkTo || []));
				return acc;
			}, [] as string[]);

		groupBys = Array.from(new Set(groupBys));
		this._reportStore = new ReportStore<T>({ reportParams: { ...reportParams, groupBys }, isGraphAvailable });
		makeAutoObservable(this);
	}
	updateTableState(newTableState: Partial<ITableState>) {
		this.tableState.updateTableState(newTableState);
	}

	getParamsFromState(): Partial<ReportParams> {
		const { pagination, sorting, columnVisibility, filters } = this._tableState;
		const groupBys = this.columns
			.filter(
				col =>
					col.alwaysVisible ||
					col.alwaysHidden ||
					col.type === 'data' ||
					(columnVisibility[col.accessor] && col.type !== 'metrics')
			)
			.reduce((acc, col) => {
				acc.push(col.name, ...(col.linkTo || []));
				return acc;
			}, [] as string[]);
		return {
			start: pagination.pageIndex * pagination.pageSize,
			limit: Math.max(pagination.pageSize, this.reportStore.reportParams.limit),
			sortBy: sorting[0]?.id,
			sortOrder: sorting[0]?.desc ? ReportSortOrder.Desc : ReportSortOrder.Asc,
			groupBys: Array.from(new Set(groupBys)),
			filters,
		};
	}

	async fetchReport(reportParams: Partial<ReportParams>, appendData = false) {
		const params = { ...this.getParamsFromState(), ...reportParams };
		await this.reportStore.fetchReport({ params, appendData });
	}

	getDefaultColumnVisibility() {
		return this.columns.reduce((acc, column) => {
			if (column.alwaysHidden || !column.visible) {
				acc[column.accessor] = false;
			} else {
				acc[column.accessor] = true;
			}
			return acc;
		}, {} as VisibilityState);
	}

	onSortingChange: OnChangeFn<SortingState> = sorting => {
		const newSorting = typeof sorting === 'function' ? sorting(this.tableState.sorting) : sorting;
		if (this.reportStore.getReportTotalRows() <= (this.reportStore.getReportData?.() ?? []).length) {
			this.updateTableState({ sorting: newSorting });
			return;
		}
		if (newSorting.length === 0) {
			this.fetchReport({
				sortBy: this.reportStore.defaultReportParams.sortBy,
				sortOrder: this.reportStore.defaultReportParams.sortOrder,
			}).then(() => {
				const defaultSorting = [
					{
						id:
							this.columns.find(c => c.name === this.reportStore.defaultReportParams.sortBy)?.accessor ??
							this.reportStore.defaultReportParams.sortBy,
						desc: this.reportStore.defaultReportParams.sortOrder === ReportSortOrder.Desc,
					},
				];
				this.updateTableState({ sorting: defaultSorting });
			});
			return;
		}
		const column = this.columns.find(col => col.accessor === newSorting[0]?.id);
		if (column) {
			this.fetchReport({ sortBy: column.name, sortOrder: newSorting[0].desc ? ReportSortOrder.Desc : ReportSortOrder.Asc }).then(
				() => {
					this.updateTableState({ sorting: newSorting });
				}
			);
		}
	};

	onColumnVisibilityChange: OnChangeFn<VisibilityState> = columnVis => {
		const columnVisibility = typeof columnVis === 'function' ? columnVis(this.tableState.columnVisibility) : columnVis;
		const newGroupBys: string[] = [];
		this.columns.forEach(col => {
			const columnsLinkedTo = col.linkTo || [];
			if (
				col.alwaysVisible ||
				col.alwaysHidden ||
				col.type === 'data' ||
				(columnVisibility[col.accessor] && col.type !== 'metrics')
			) {
				newGroupBys.push(col.name);
				newGroupBys.push(...columnsLinkedTo);
			}
		});
		const groupBys = Array.from(new Set(newGroupBys));
		if (groupBys.length !== this.reportStore.reportParams.groupBys.length) {
			this.fetchReport({ groupBys });
			this.updateTableState({ rowSelection: {} });
		}

		this.updateTableState({ columnVisibility });
	};

	onPaginationChange: OnChangeFn<PaginationState> = pagination => {
		const newPagination = typeof pagination === 'function' ? pagination(this.tableState.pagination) : pagination;
		this.updateTableState({ pagination: newPagination });
		const newStart = newPagination.pageIndex * newPagination.pageSize;
		const newLimit = newPagination.pageSize;
		const oldLimit = this.reportStore.reportParams.start + this.reportStore.reportParams.limit;
		if (newStart >= oldLimit || newLimit > oldLimit) {
			this.fetchReport(
				{
					start: newStart,
					limit: Math.max(newPagination.pageSize, this.reportStore.reportParams.limit),
				},
				true
			);
		}
	};

	onRowSelectionChange: OnChangeFn<RowSelectionState> = rowSelection => {
		const newRowSelection = typeof rowSelection === 'function' ? rowSelection(this.tableState.rowSelection) : rowSelection;
		this.updateTableState({ rowSelection: newRowSelection });
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getRowId = (row: any) => {
		let rowId = '';
		this.reportStore.reportParams.groupBys.forEach(groupBy => {
			const accessor = this.columns.find(col => col.name === groupBy)?.accessor;
			if (accessor && row[accessor]) {
				rowId += `${row[accessor]}_`;
			}
		});
		return rowId;
	};

	onFilterChange = (filters: ReportFilter[]) => {
		this.updateTableState({ filters });
		this.fetchReport({ filters });
	};

	get reportStore() {
		return this._reportStore;
	}

	set reportStore(value: ReportStore<T>) {
		this._reportStore = value;
	}

	get columns() {
		return this._columns;
	}

	set columns(value: IColumnConfig<T>[]) {
		this._columns = value;
	}

	get tableState() {
		return this._tableState;
	}

	set tableState(value: TableState) {
		this._tableState = value;
	}

	setDefaultFilters(filters: ReportFilter[]) {
		this.reportStore.setDefaultFilters(filters);
	}

	downloadCsv() {
		this.reportStore.downloadReportFileCsv();
	}
}
