import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';
import { CampaignTargetingLinksCid } from './campaign-targeting-links-cid';

interface ICampaignTargetingLinks {
	id?: id;
	cids?: CampaignTargetingLinksCid[];
	dest_link?: string;
	link_group?: string;
	max_conversions?: number;
	pid?: string;
	publisher_name?: string;
	status?: string;
	weight?: number;
	yid?: string;
}

export class CampaignTargetingLinks implements ICampaignTargetingLinks {
	id?: id;
	cids?: CampaignTargetingLinksCid[];
	dest_link?: string;
	link_group?: string;
	max_conversions?: number;
	pid?: string;
	publisher_name?: string;
	status?: string;
	weight?: number;
	yid?: string;

	constructor(data: ICampaignTargetingLinks) {
		this.id = data?.id || 0;
		this.cids = data?.cids;
		this.dest_link = data?.dest_link;
		this.link_group = data?.link_group;
		this.max_conversions = data?.max_conversions;
		this.pid = data?.pid;
		this.publisher_name = data?.publisher_name;
		this.status = data?.status;
		this.weight = data?.weight;
		this.yid = data?.yid;

		makeAutoObservable(this);
	}

	getId() {
		return this.id;
	}

	getCids() {
		return this.cids;
	}

	getDestLink() {
		return this.dest_link;
	}

	getLinkGroup() {
		return this.link_group;
	}

	getMaxConversions() {
		return this.max_conversions;
	}

	getPid() {
		return this.pid;
	}

	getPublisherName() {
		return this.publisher_name;
	}

	getStatus() {
		return this.status;
	}

	getWeight() {
		return this.weight;
	}

	getYid() {
		return this.yid;
	}

	setId(id: id) {
		this.id = id;
	}

	setCids(cids: CampaignTargetingLinksCid[]) {
		this.cids = cids;
	}

	setDestLink(destLink: string) {
		this.dest_link = destLink;
	}

	setLinkGroup(linkGroup: string) {
		this.link_group = linkGroup;
	}

	setMaxConversions(maxConversions: number) {
		this.max_conversions = maxConversions;
	}

	setPid(pid: string) {
		this.pid = pid;
	}

	setPublisherName(publisherName: string) {
		this.publisher_name = publisherName;
	}

	setStatus(status: string) {
		this.status = status;
	}

	setWeight(weight: number) {
		this.weight = weight;
	}

	setYid(yid: string) {
		this.yid = yid;
	}
}
