import { CellContext } from '@tanstack/react-table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../../routes/index.routes';

export const advertiserNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.advertisers.view(row.getValue('advertiserId'))}>{row.getValue('advertiserName')}</TableLink>;
};
export const campaignNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.campaigns.view(row.getValue('campaignId'))}>{row.getValue('campaignName')}</TableLink>;
};
export const campaignGroupNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.campaignGroups.view(row.getValue('campaignGroupId'))}>{row.getValue('campaignGroupName')}</TableLink>;
};
export const creativeGroupNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.creativeGroups.view(row.getValue('creativeGroupId'))}>{row.getValue('creativeGroupName')}</TableLink>;
};
