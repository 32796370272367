import { CellContext } from '@tanstack/react-table';
import { ColumnType, IFilterDefinition, IColumnConfig } from '../report.types';
import { currencyFormat, percents } from '@monorepo/tools/src/lib/utils/number';
import { NumbersFilterDefinition } from '../table-filters/filters.definitions';

type ColumnRequiredConfig<T> = Required<Pick<IColumnConfig<T>, 'name' | 'accessor' | 'header'>> & Partial<IColumnConfig<T>>;

export class ColumnConfig<T = unknown> implements IColumnConfig<T> {
	name: string;
	accessor: string;
	header: string;
	availableFilters?: IFilterDefinition;
	visible: boolean;
	type: ColumnType;
	alwaysVisible?: boolean;
	alwaysHidden?: boolean;
	cell?: (info: CellContext<T, unknown>) => void;
	linkTo?: string[];
	footerFormatter: (value: unknown) => string;

	constructor(column: ColumnRequiredConfig<T>) {
		this.name = column.name;
		this.accessor = column.accessor;
		this.header = column.header;
		this.availableFilters = column.availableFilters;
		this.visible = column.visible ?? true;
		this.type = column.type ?? 'data';
		this.alwaysVisible = column.alwaysVisible;
		this.alwaysHidden = column.alwaysHidden;
		this.cell = column.cell;
		this.linkTo = column.linkTo;
		this.footerFormatter = column.footerFormatter || (value => (value as number)?.toLocaleString() ?? '-');
	}
}

export class MetricsColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({
			type: 'metrics',
			availableFilters: new NumbersFilterDefinition(),
			...column,
		});
		this.cell = ({ row }) => <span>{row.getValue(column.accessor)?.toLocaleString() ?? '-'}</span>;
	}
}

export class MetricsCurrencyColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...column });
		this.cell = ({ row }) => <span>{currencyFormat(row.getValue(column.accessor)) ?? '-'}</span>;
		this.footerFormatter = value => currencyFormat(value as number) ?? '-';
	}
}
export class MetricsPercentageColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({ ...column });
		this.cell = ({ row }) => <span>{percents(row.getValue(column.accessor)) ?? '0%'}</span>;
		this.footerFormatter = value => percents(value as number) ?? '0%';
	}
}

export class DimensionsColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({
			type: 'dimensions' as ColumnType,
			...column,
		});
	}
}

export class DataColumnConfig<T> extends ColumnConfig<T> {
	constructor(column: ColumnRequiredConfig<T>) {
		super({
			type: 'data' as ColumnType,
			...column,
		});
	}
}
