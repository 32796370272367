import { ReactElement, useEffect } from 'react';
import styles from './table-elements.module.scss';
import { PrimaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useTableContext } from '../table-component';
import { DividerX } from '@monorepo/base/src/components/divider/divider';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';

type TableRawActionChildrens = ReactElement<typeof TableRawActionButton>;
const theme = getTheme();

interface TableRawActionButtonProps {
	text?: string;
	to?: string;
	onClick?: () => void;
	icon?: string;
}

export const TableRawActionButton = ({ to, onClick, text = 'Create', icon = 'plus' }: TableRawActionButtonProps) => {
	return (
		<PrimaryLink onClick={onClick} icon={icon} to={to} width={'100px'}>
			{text}
		</PrimaryLink>
	);
};

interface TableActionBarProps {
	children?: TableRawActionChildrens;
}

export const TableActionsBar = observer(({ children }: TableActionBarProps) => {
	const { tableStore } = useTableContext();
	const selectedRows = Object.values(tableStore.tableState.rowSelection).filter(Boolean).length;
	return (
		<div className={`${styles.rawActionBar} ${selectedRows === 0 ? styles.nonSelected : ''}`}>
			<div className={styles.rawActionBarButtons}>
				<div className={styles.rawActionBarNumberOfSelected}>{selectedRows} selected</div>
				<DividerX height={'20px'} />
				{children}
			</div>
			<div className={styles.rawActionBarButtons}>
				<TableRawActionButton text={'close'} onClick={() => tableStore.onRowSelectionChange({})} icon={'x'} />
			</div>
		</div>
	);
});

export const ExportToCSV = observer(() => {
	const { tableStore } = useTableContext();
	useEffect(() => tableStore.reportStore.downloadCsv.setIsLoading(false), []);
	const isDownloadCsv = tableStore.reportStore.downloadCsv.getIsLoading();
	const isTableLoading = tableStore.reportStore.getIsLoading();
	return (
		<IndexLinerButton>
			<SecondaryText
				icon={'file-download-02'}
				font={IconFonts.Outlined}
				iconColor={theme.actionLinerActionIconsColor}
				iconSize={'18px'}
				disabled={isTableLoading}
				onClick={() => tableStore.reportStore.downloadReportFileCsv(tableStore.tableState.tableName)}>
				<span>Export</span>
			</SecondaryText>
			<BarLoader is={isDownloadCsv} />
		</IndexLinerButton>
	);
});
