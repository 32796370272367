import { IDomainCampaignPerformance } from '../../../../models/domain.models';
import { DomainAdxColumnConfig } from '../../predefined-columns/adx.columns';
import { generatePerformanceColumns } from '../../predefined-columns/metrics.columns';
import { ColumnType, ReportSortOrder } from '../../report.types';

export const domainsPerformanceTableConfig = {
	reportParams: {
		reportName: 'adx',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
	},
	columns: [
		new DomainAdxColumnConfig<IDomainCampaignPerformance>({ alwaysVisible: true, type: 'data' as ColumnType }),
		...generatePerformanceColumns<IDomainCampaignPerformance>(),
	],
	isGraphAvailable: false,
	tableName: 'domainsReport',
};
