import { IDomainCampaignPerformance } from '../../../../models/domain.models';
import {
	AdvertiserIdAdxColumnConfig,
	AdvertiserNameAdxColumnConfig,
	CampaignGroupIdAdxColumnConfig,
	CampaignGroupNameAdxColumnConfig,
	CampaignIdAdxColumnConfig,
	CampaignNameAdxColumnConfig,
	DomainAdxColumnConfig,
} from '../../predefined-columns/adx.columns';
import { generatePerformanceColumns } from '../../predefined-columns/metrics.columns';
import { ReportSortOrder } from '../../report.types';

export const domainCampaignsPerformanceTableConfig = {
	reportParams: {
		reportName: 'adx',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
		submit: true,
	},
	columns: [
		new CampaignNameAdxColumnConfig<IDomainCampaignPerformance>(),
		new CampaignIdAdxColumnConfig<IDomainCampaignPerformance>(),
		new AdvertiserNameAdxColumnConfig<IDomainCampaignPerformance>(),
		new AdvertiserIdAdxColumnConfig<IDomainCampaignPerformance>(),
		new CampaignGroupNameAdxColumnConfig<IDomainCampaignPerformance>(),
		new CampaignGroupIdAdxColumnConfig<IDomainCampaignPerformance>(),
		new DomainAdxColumnConfig<IDomainCampaignPerformance>({ alwaysHidden: true }),
		...generatePerformanceColumns<IDomainCampaignPerformance>(),
	],
	isGraphAvailable: true,
	tableName: 'domainCampaignsPerformance',
};
