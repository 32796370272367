import { IDomainPertargetingStatus } from '../../../../models/domain.models';
import {
	DomainBillingIdColumnConfig,
	DomainPretargetingModeColumnConfig,
	DomainNameColumnConfig,
	DomainPretargetingIdColumnConfig,
	DomainTypeColumnConfig,
} from '../../predefined-columns/domains.columns';
import { ReportSortOrder } from '../../report.types';

export const domainPretargetingStatusTableConfig = {
	reportParams: {
		reportName: 'adxDomainPretargetingStatus',
		sortBy: 'name',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new DomainPretargetingIdColumnConfig<IDomainPertargetingStatus>(),
		new DomainBillingIdColumnConfig<IDomainPertargetingStatus>(),
		new DomainTypeColumnConfig<IDomainPertargetingStatus>(),
		new DomainPretargetingModeColumnConfig<IDomainPertargetingStatus>(),
		new DomainNameColumnConfig<IDomainPertargetingStatus>(),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsPretargetingStatusTable',
};
