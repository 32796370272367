import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';
import { Fragment, useState } from 'react';
import { FilterApplyAndCancelButtons, FilterOptionProps } from './table-filters';
import styles from './table-filters.module.scss';
import { FilterOptions } from './filter-options';

export const TableSelectFilter = <T,>({ column, onApply, onCanceled, initialState }: FilterOptionProps<T>) => {
	const filter = column.availableFilters;
	const [action, setAction] = useState(filter?.actions.find(action => action.value === initialState?.filterType) || filter?.actions[0]);
	const [option, setOption] = useState<(string | number)[]>(initialState?.value || []);
	const availableFilterActions = filter?.actions.map(action => ({ label: action.label, value: action.value })) || [];
	const availableFilterOptions = filter?.options || [];

	const handleApply = () => {
		if (!action || !option) {
			return;
		}
		onApply({ filterType: action.value, value: option, column: column.name });
	};

	return (
		<Fragment>
			<div className={styles.filterTitle}>{column.header}</div>
			<div className={styles.filterActionPicker}>
				<Dropdown
					required={true}
					options={availableFilterActions.map(x => x.label)}
					onSelect={val => setAction(val ? availableFilterActions.find(action => action.label === Array.from(val)[0]) : val)}
					autocomplete={availableFilterActions.length > 10}
					defaultOption={action?.label || ''}
					disabled={availableFilterActions.length === 1}
					autocompletePlaceholder={'Search action'}
				/>
			</div>
			<div className={styles.filterActionPicker}>
				<FilterOptions isMultiple={filter?.multi} options={availableFilterOptions} value={option} onEnumFilterOption={setOption} />
			</div>
			<FilterApplyAndCancelButtons
				onApply={handleApply}
				onCanceled={onCanceled}
				disableApply={
					!action ||
					!option ||
					(action.value === initialState?.filterType && JSON.stringify(option) === JSON.stringify(initialState.value))
				}
			/>
		</Fragment>
	);
};
