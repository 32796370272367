import { campaignNameCell, creativeGroupNameCell } from '../cells/adminx.cells';
import { IColumnConfig } from '../report.types';
import { TextFilterDefinition } from '../table-filters/filters.definitions';
import { DimensionsColumnConfig } from './columns';

export class CampaignNameColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'campaign_name',
			accessor: 'campaignName',
			header: 'Campaign',
			linkTo: ['campaign_id'],
			cell: campaignNameCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class CampaignIdColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'campaign_id',
			accessor: 'campaignId',
			header: 'Campaign ID',
			linkTo: ['campaign_name'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class CreativeGroupNameColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'creative_group_name',
			accessor: 'creativeGroupName',
			header: 'Creative Group',
			linkTo: ['creative_group_id'],
			cell: creativeGroupNameCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class CreativeGroupIdColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'creative_group_id',
			accessor: 'creativeGroupId',
			linkTo: ['creative_group_name'],
			header: 'Creative Group ID',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}
