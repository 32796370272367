import { FilterType, IFilterDefinition, ReportFilterType } from '../report.types';

export class FilterDefinition implements IFilterDefinition {
	type;
	actions;
	options;
	multi;
	selectFromDataSource;
	constructor(defintion: IFilterDefinition) {
		this.type = defintion.type;
		this.actions = defintion.actions;
		this.options = defintion.options;
		this.multi = defintion.multi;
		this.selectFromDataSource = defintion.selectFromDataSource;
	}
}

export class NumbersFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.Number,
			actions: [
				{ label: 'Greater', value: ReportFilterType.GREATER },
				{ label: 'Greater and Equals', value: ReportFilterType.GREATER_AND_EQUALS },
				{ label: 'Less', value: ReportFilterType.LESS },
				{ label: 'Less and Equals', value: ReportFilterType.LESS_AND_EQUALS },
				{ label: 'Equals', value: ReportFilterType.EQUALS },
				{ label: 'Not Equals', value: ReportFilterType.NOT_EQUALS },
			],
		});
	}
}

export class TextFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.Text,
			actions: [
				{ label: 'Include', value: ReportFilterType.INCLUDE },
				{ label: 'Contains', value: ReportFilterType.CONTAINS },
			],
		});
	}
}
