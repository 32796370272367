import { advertiserNameAdxCell, campaignGroupNameAdxCell, campaignNameAdxCell, domainLinkCell } from '../cells/adx.cells';
import ReportStore from '../report.store';
import { FilterType, ReportFilterType } from '../report.types';
import { NumbersFilterDefinition, TextFilterDefinition } from '../table-filters/filters.definitions';
import { DimensionsColumnConfig, ColumnConfig } from './columns';

interface IEntityIdFilterData {
	id: string;
	name: string;
}

// "campaign"
export class CampaignNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'campaign',
			accessor: 'campaign',
			header: 'Campaign Name',
			linkTo: ['campaign_id'],
			cell: campaignNameAdxCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "campaign_id"
export class CampaignIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'campaign_id',
			accessor: 'campaignId',
			header: 'Campaign ID',
			linkTo: ['campaign'],
			availableFilters: {
				type: FilterType.SelectFromDataSource,
				actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
				selectFromDataSource: {
					store: new ReportStore<IEntityIdFilterData>({
						reportParams: {
							reportName: 'adxCampaigns',
							groupBys: ['name', 'id'],
							sortBy: 'name',
						},
						isGraphAvailable: false,
					}),
					cb: row => {
						const rowAs = row as IEntityIdFilterData;
						return {
							value: rowAs.id,
							label: `${rowAs.name} (${rowAs.id})`,
						};
					},
				},
			},
			...params,
		});
	}
}

// "advertiser"
export class AdvertiserNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'advertiser',
			accessor: 'advertiser',
			linkTo: ['advertiser_id'],
			header: 'Advertiser Name',
			cell: advertiserNameAdxCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "advertiser_id"
export class AdvertiserIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'advertiser_id',
			accessor: 'advertiserId',
			linkTo: ['advertiser'],
			header: 'Advertiser ID',
			availableFilters: {
				type: FilterType.SelectFromDataSource,
				actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
				selectFromDataSource: {
					store: new ReportStore<IEntityIdFilterData>({
						reportParams: {
							reportName: 'adxAdvertisers',
							groupBys: ['name', 'id'],
							sortBy: 'name',
						},
						isGraphAvailable: false,
					}),
					cb: row => {
						const rowAs = row as IEntityIdFilterData;
						return {
							value: rowAs.id,
							label: `${rowAs.name} (${rowAs.id})`,
						};
					},
				},
			},
			...params,
		});
	}
}

// "campaign_group"
export class CampaignGroupNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'campaign_group',
			accessor: 'campaignGroup',
			linkTo: ['campaign_group_id'],
			header: 'Campaign Group',
			cell: campaignGroupNameAdxCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "campaign_group_id"
export class CampaignGroupIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'campaign_group_id',
			accessor: 'campaignGroupId',
			linkTo: ['campaign_group'],
			header: 'Campaign Group ID',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "creative_brand_name"
export class CreativeBrandAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'creative_brand_name',
			accessor: 'creativeBrandName',
			header: 'Creative Brand/Name',
			linkTo: ['creative_id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "creative_id"
export class CreativeIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'creative_id',
			accessor: 'creativeId',
			header: 'Creative ID',
			linkTo: ['creative_brand_name'],
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

// "creative_group_design"
export class CreativeGroupDesignAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'creative_group_design',
			accessor: 'creativeGroupDesign',
			header: 'Creative Group Design',
			linkTo: ['creative_group_id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "creative_group_id"
export class CreativeGroupIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'creative_group_id',
			accessor: 'creativeGroupId',
			header: 'Creative Group ID',
			linkTo: ['creative_group_design'],
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

// "strategy_id"
export class StrategyIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'strategy_id',
			accessor: 'strategyId',
			header: 'Strategy ID',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

// "creative_size"
export class CreativeSizeAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'creative_size',
			accessor: 'creativeSize',
			header: 'Creative Size',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "test_id"
export class TestIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'test_id',
			accessor: 'testId',
			header: 'Test ID',
			linkTo: ['test_name'],
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

// "test_name"
export class TestNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'test_name',
			accessor: 'testName',
			header: 'Test Name',
			linkTo: ['test_id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "test_group_name"
export class TestGroupNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'test_group_name',
			accessor: 'testGroupName',
			header: 'Test Group Name',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "page"
export class PageAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'page',
			accessor: 'page',
			header: 'Page',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class PreferredDealIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'preferred_deal_id',
			accessor: 'preferredDealId',
			header: 'Preferred Deal ID',
			linkTo: ['preferred_deal_name'],
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

// "preferred_deal_name"
export class PreferredDealNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'preferred_deal_name',
			accessor: 'preferredDealName',
			header: 'Preferred Deal Name',
			linkTo: ['preferred_deal_id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "domain"
export class DomainAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'domain',
			accessor: 'domain',
			header: 'Domain',
			cell: domainLinkCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "publisher_id"
export class PublisherIdAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'publisher_id',
			accessor: 'publisherId',
			header: 'Publisher ID',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "browser_name"
export class BrowserNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'browser_name',
			accessor: 'browserName',
			header: 'Browser Name',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "os_name"
export class OsNameAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'os_name',
			accessor: 'osName',
			header: 'OS Name',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "geo"
export class GeoAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'geo',
			accessor: 'geo',
			header: 'Geo',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "state"
export class StateAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'state',
			accessor: 'state',
			header: 'State',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "city"
export class CityAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'city',
			accessor: 'city',
			header: 'City',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}
// "auction_type"
export class AuctionTypeAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'auction_type',
			accessor: 'auctionType',
			header: 'Auction Type',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "bid_key"
export class BidKeyAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'bid_key',
			accessor: 'bidKey',
			header: 'Bid Key',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "Date"
export class DateAdxColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'dt',
			accessor: 'date',
			header: 'Date',
			...params,
		});
	}
}

// Strategy Id
export class StrategyIdColumnConfig<T> extends DimensionsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({
			name: 'strategy_id',
			accessor: 'strategyId',
			header: 'Strategy ID',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}
