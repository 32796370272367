import { Fragment } from 'react';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { AdvertiserModel } from '../../models/advertiser.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, IUseEntityActions, useDefaultActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { advertiserFilterPrototypes } from '../../components/filters-menu/filters';

const theme = getTheme();

export function useAdvertiserActions(props: IUseEntityActions = {}) {
	const { createRoute, onEdit, debugProps } = props;
	const { advertiserStore, settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(
			currentRouteWithoutSpecialChars === 'advertiserscreate' || currentRouteWithoutSpecialChars === 'advertisersedit'
				? 'advertisers'
				: currentRouteWithoutSpecialChars
		);

	const currentFilters = filterStore?.getCurrentFilters();
	const DefaultAvailableFilters = [...advertiserFilterPrototypes];

	// const performanceStore = advertiserStore.getPerformance();
	const onEditRoute = (advertiserId: id) => (onEdit ? onEdit(advertiserId) : routes.advertisers.edit(advertiserId));
	const onDelete = (ids: id[]) => advertiserStore.getCrud().delete(ids[0]);

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const SelectedActions = (props: ITableActions<AdvertiserModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new AdvertiserModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, debugProps, ...funcActions });
		const { dataAttrs } = debugProps || {};

		return (
			<Fragment>
				<SelectedLinerButton>
					<EditAction
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }}
					/>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_delete', dataAttrs) }} />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<AdvertiserModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction } = useDefaultActions({ entities: [entity], debugProps, ...funcActions });

		return (
			<Fragment>
				<QuickActionButton>
					<EditAction
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
					/>
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const IndexActions = () => {
		return (
			<PrimaryLink
				icon={'plus'}
				to={`${createRoute || routes.advertisers.create()}`}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'create_advertiser')] }}
				width={'100px'}
				state={{ isBlockRequest: true } as BetweenPagesStateProps}>
				Create
			</PrimaryLink>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	// const SegmentActions = (props: ISegmentActions) => {
	// 	const { segments, onSegment } = useSegments<AdvertiserModel>({ performanceStore });

	// 	return (
	// 		<Segments<AdvertiserModel, PerformanceModel, PerformancesColumnsSnakeCase>
	// 			currentSegments={performanceStore.getSegments()}
	// 			availableSegments={segments}
	// 			onClick={segment => onSegment(segment)}
	// 			{...props}
	// 		/>
	// 	);
	// };

	return {
		SelectedActions,
		EntityActions,
		FiltersActions,
		// SegmentActions,
		IndexActions,
	};
}
