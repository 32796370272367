import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../../modules/routes/index.routes';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { IPerformanceOptionsFilterType } from '../../../../modules/hooks/apis/use-performance';
import { useRoute } from '../../../../modules/hooks/tools/use-route';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { useCampaignHeaders } from '../../../../modules/hooks/theaders/campaigns.headers';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IAdminxStore } from '../../../../modules/stores';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
	groupByMapper,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

export const AdvertiserCampaigns = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const metricsStore = campaignStore.getMetrics();
	const { advertiserId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const reportList = campaignStore.getListStore();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	const advertiserFilter = [
		{
			column: PerformancesColumnsSnakeCase.AdvertiserId,
			filterType: IPerformanceOptionsFilterType.Include,
			value: [advertiserId as string],
		},
	];
	const { fetchReports } = useReportsList(reportList, {
		groupBys: groupByColumns,
		reportName: 'adxCampaigns',
		sortBy: defaultSortBy?.[0]?.id || IPerformanceOptionsSortBy.Wins,
		sortOrder: defaultSortBy?.[0]?.desc ? IPerformanceOptionsSortOrder.Desc : IPerformanceOptionsSortOrder.Asc,
		dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
			? IPerformanceOptionsDateSegments.Daily
			: IPerformanceOptionsDateSegments.None,
		filters: advertiserFilter,
	});
	useReportsList(metricsStore, {
		groupBys: [PerformancesColumnsSnakeCase.Dt],
		reportName: 'adxCampaigns',
		dateSegment: IPerformanceOptionsDateSegments.Daily,
		filters: advertiserFilter,
	});

	const { columns } = useCampaignHeaders({ includeColumns: { advertiser: false }, summary: reportList.getData()?.getFooter() });
	const { SelectedActions, FiltersActions } = useCampaignActions();
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaigns_advertiser_table')] }}
				createRoute={advertiserId ? routes.advertisers.createCampaign(advertiserId) : undefined}
				columns={columns}
				data={reportList.getData()?.getData() || []}
				isLoading={reportList.getIsLoading()}
				fetchReports={fetchReports}
				isSummary={true}>
				{() => {
					return {
						FiltersActions,
						SelectedActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
