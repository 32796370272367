import React, { Fragment, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { VisibilityState } from '@tanstack/react-table';
import styles from './column-picker.module.scss';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { PrimaryLink, PrimaryText, SecondaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useTableContext } from '../table-component';

interface ColumnPickerProps {
	onClose?: () => void;
}

export const ColumnPickerOpenButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<Fragment>
			<IndexLinerButton>
				<SecondaryText
					icon={'columns-03'}
					font={IconFonts.Outlined}
					iconColor={getTheme().actionLinerActionIconsColor}
					iconSize={'18px'}
					onClick={onClick}>
					Columns
				</SecondaryText>
			</IndexLinerButton>
		</Fragment>
	);
};

const ColumnPicker = observer(<T,>({ onClose: onCloseCB }: ColumnPickerProps) => {
	const { tableStore } = useTableContext<T>();
	const [updatedColumnsVisibility, setUpdatedColumnsVisibility] = useState({ ...tableStore.tableState.columnVisibility });
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const columnsPickerRef = useRef<HTMLDivElement>(null);

	const isChanged = Object.keys(updatedColumnsVisibility).some(
		key => updatedColumnsVisibility[key] !== tableStore.tableState.columnVisibility[key]
	);
	const isAllSelected = Object.keys(updatedColumnsVisibility).every(key => updatedColumnsVisibility[key]);
	const isReseted = tableStore.columns.every(
		col => col.alwaysHidden || col.alwaysVisible || updatedColumnsVisibility[col.accessor] === col.visible
	);

	const handleCheckboxChange = (accessor: string) => {
		setUpdatedColumnsVisibility(prev => ({
			...prev,
			[accessor]: !prev[accessor],
		}));
	};

	const onClose = () => {
		onCloseCB?.();
		setIsPickerOpen(false);
	};

	const handleReset = () => {
		const columnVisibility: VisibilityState = {};
		tableStore.columns.forEach(col => {
			if (col.alwaysVisible || col.alwaysHidden) {
				return;
			}
			columnVisibility[col.accessor] = col.visible;
		});
		setUpdatedColumnsVisibility(columnVisibility);
	};

	const handleApply = () => {
		tableStore.onColumnVisibilityChange(updatedColumnsVisibility);
		onClose();
	};

	const handleSelectAll = () => {
		const columnVisibility: VisibilityState = {};
		Object.keys(updatedColumnsVisibility).forEach(key => {
			columnVisibility[key] = true;
		});
		setUpdatedColumnsVisibility(columnVisibility);
	};

	useOnClickOutside(columnsPickerRef, () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.INDEX_PAGE.ACTION_LINER.COLUMN_PICKER.COLLAPSE,
			value: window.location.href,
		});
		onClose();
	});

	const dimensionsColumns = tableStore.columns.filter(col => col.type === 'dimensions' && !(col.alwaysHidden || col.alwaysVisible));
	const dataColumns = tableStore.columns.filter(col => col.type !== 'dimensions' && !(col.alwaysHidden || col.alwaysVisible));

	return (
		<div ref={columnsPickerRef} className={styles.columnPickerWrapper}>
			<ColumnPickerOpenButton onClick={() => (isPickerOpen ? onClose() : setIsPickerOpen(!isPickerOpen))} />
			<div className={`${styles.columnPicker} ${isPickerOpen ? styles.open : ''}`}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div className={styles.columnPickerSectionHeader}>
						<h4 className={styles.columnPickerSectionTitle}>{dimensionsColumns.length ? 'Dimensions' : 'Metrics'} Columns</h4>
						<PrimaryText onClick={handleSelectAll} disabled={isAllSelected} className={styles.columnPickerSelectAllButton}>
							Select All
						</PrimaryText>
					</div>
					{dimensionsColumns.length ? (
						<Fragment>
							<div className={styles.list}>
								{dimensionsColumns
									.sort((a, b) => {
										if (a.header < b.header) {
											return -1;
										}
										if (a.header > b.header) {
											return 1;
										}
										return 0;
									})
									.map(col => (
										<label
											className={styles.checkboxWrapper}
											key={col.accessor}
											style={{ display: 'block', margin: '5px 0' }}>
											<input
												className={styles.checkbox}
												type="checkbox"
												checked={updatedColumnsVisibility[col.accessor]}
												onChange={() => handleCheckboxChange(col.accessor)}
											/>
											{col.header}
										</label>
									))}
							</div>
							<div className={styles.columnPickerSectionHeader}>
								<h4 className={styles.columnPickerSectionTitle}>Metrics Columns</h4>
							</div>
						</Fragment>
					) : null}

					<div className={styles.list}>
						{dataColumns
							.sort((a, b) => {
								if (a.header < b.header) {
									return -1;
								}
								if (a.header > b.header) {
									return 1;
								}
								return 0;
							})
							.map(col => (
								<label className={styles.checkboxWrapper} key={col.accessor} style={{ display: 'block', margin: '5px 0' }}>
									<input
										className={styles.checkbox}
										type="checkbox"
										checked={updatedColumnsVisibility[col.accessor]}
										onChange={() => handleCheckboxChange(col.accessor)}
									/>
									{col.header}
								</label>
							))}
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
					<SecondaryLink
						font={IconFonts.Outlined}
						iconColor={getTheme().actionLinerActionIconsColor}
						className={styles.columnPickerResetButton}
						iconSize={'18px'}
						width={'80px'}
						disabled={isReseted}
						onClick={handleReset}>
						Reset
					</SecondaryLink>
					<PrimaryLink
						width={'80px'}
						onClick={handleApply}
						disabled={!isChanged}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'column-picker-apply')] }}>
						Apply
					</PrimaryLink>
				</div>
			</div>
		</div>
	);
});

export default ColumnPicker;
