import { observer } from 'mobx-react';
import { FC } from 'react';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useAdvertiserActions } from '../../../modules/hooks/actions/advertiser.actions';
import { Outlet } from 'react-router-dom';
import { useAdvertiserHeaders } from '../../../modules/hooks/theaders/advertisers.headers';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { IAdminxStore } from '../../../modules/stores';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import {
	PerformancesColumnsSnakeCase,
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	groupByMapper,
	useReportsList,
	IPerformanceOptionsSortOrder,
} from '../../../modules/hooks/apis/use-reports-list';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'advertisers_actions')] };
const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'pagebar')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'advertisers_table')] };
const chartDebugProps = { dataAttrs: [new DataAttribute('id', 'advertisers_chart')] };

export const Advertisers: FC = observer(() => {
	const { advertiserStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const reportList = advertiserStore.getListStore();
	const metricsStore = advertiserStore.getMetrics();
	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	const { fetchReports } = useReportsList(reportList, {
		groupBys: [...groupByColumns],
		reportName: 'adxAdvertisers',
		sortBy: defaultSortBy?.[0]?.id || IPerformanceOptionsSortBy.Wins,
		sortOrder: defaultSortBy?.[0]?.desc ? IPerformanceOptionsSortOrder.Desc : IPerformanceOptionsSortOrder.Asc,
		submit: true,
		dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
			? IPerformanceOptionsDateSegments.Daily
			: IPerformanceOptionsDateSegments.None,
	});

	useReportsList(metricsStore, {
		groupBys: [PerformancesColumnsSnakeCase.Dt],
		reportName: 'adxAdvertisers',
		dateSegment: IPerformanceOptionsDateSegments.Daily,
		submit: false,
	});

	const { SelectedActions, IndexActions, EntityActions, FiltersActions } = useAdvertiserActions({
		debugProps: actionsDebugProps,
	});
	const { columns } = useAdvertiserHeaders({ EntityActions, summary: reportList.getData()?.footer }); //summary
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Advertisers</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Card full debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}>
				<Chart
					debugProps={chartDebugProps}
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={
					currentRouteWithoutSpecialChars === 'advertiserscreate' || currentRouteWithoutSpecialChars === 'advertisersedit'
						? 'advertisers'
						: currentRouteWithoutSpecialChars
				}
				debugProps={tableDebugProps}
				columns={columns}
				data={reportList.getData()?.data || []}
				fetchReports={fetchReports}
				defaultSortBy={defaultSortBy}
				isLoading={advertiserStore.getListStore().getIsLoading()}
				isGlobalFilter={false}
				isSummary={true}>
				{() => {
					return {
						IndexActions,
						SelectedActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
