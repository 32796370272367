import { IDomainScore } from '../../../../models/domain.models';
import {
	DomainFormatColumnConfig,
	DomainSalesColumnConfig,
	DomainScoreColumnConfig,
	DomainScorerClassColumnConfig,
} from '../../predefined-columns/domains.columns';
import { WinsColumnConfig } from '../../predefined-columns/metrics.columns';
import {
	CampaignIdColumnConfig,
	CampaignNameColumnConfig,
	CreativeGroupIdColumnConfig,
	CreativeGroupNameColumnConfig,
} from '../../predefined-columns/aggregations.columns';
import { ReportSortOrder } from '../../report.types';
import { CreatedAtColumnConfig, CreatedByColumnConfig, UpdatedAtColumnConfig } from '../../predefined-columns/data.columns';

export const domainScoresTableConfig = {
	reportParams: {
		reportName: 'adxDomainsScore',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new CampaignNameColumnConfig<IDomainScore>(),
		new CampaignIdColumnConfig<IDomainScore>(),
		new CreativeGroupNameColumnConfig<IDomainScore>(),
		new CreativeGroupIdColumnConfig<IDomainScore>(),
		new DomainScoreColumnConfig<IDomainScore>(),
		new DomainFormatColumnConfig<IDomainScore>(),
		new DomainScorerClassColumnConfig<IDomainScore>(),
		new WinsColumnConfig<IDomainScore>({ type: 'data' }),
		new DomainSalesColumnConfig<IDomainScore>(),
		new CreatedByColumnConfig<IDomainScore>(),
		new CreatedAtColumnConfig<IDomainScore>(),
		new UpdatedAtColumnConfig<IDomainScore>(),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsScoreTable',
};
