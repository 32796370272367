import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IBidderCampaignEditForm } from '../models/bidder-campaign/bidder-camapign.model';

type UpdateCampaignResponse = {
	MetaData: {
		campaign_id: number;
		campaign_group_id: number;
		advertiser_id: number;
	};
	success: true;
};

export const BidderApi = {
	campaigns: (): Promise<any> => {
		return ask.get(endpoints.bidder.campaigns());
	},

	getBidderCampaign: (id: id): Promise<any> => {
		return ask.get(endpoints.bidder.getCampaign(id));
	},

	updateCampaign: (body: IBidderCampaignEditForm): Promise<UpdateCampaignResponse> => {
		return ask.put(endpoints.bidder.updateCampaign(), body);
	},

	enableCampaign: (campaignId: id): Promise<UpdateCampaignResponse> => {
		return ask.put(endpoints.bidder.enableCampaign(campaignId), {});
	},

	pauseCampaign: (campaignId: id): Promise<UpdateCampaignResponse> => {
		return ask.put(endpoints.bidder.pauseCampaign(campaignId), {});
	},
};
