import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CampaignTargetingLinks } from './campaign-targeting-links';
import { makeAutoObservable } from 'mobx';

interface ICampaignTargeting {
	id?: id;
	black_hour?: string;
	browser?: string;
	campaign_targeting_links?: CampaignTargetingLinks[];
	country_code?: string;
	device_type?: string;
	estimated_rate?: number;
	excluded_browser?: string;
	os?: string;
	q_estimated_rate?: number;
	rh_offer_id?: string;
	rh_publisher_id?: string;
	status?: string;
}

export class CampaignTargeting implements ICampaignTargeting {
	id?: id;
	black_hour?: string;
	browser?: string;
	campaign_targeting_links?: CampaignTargetingLinks[];
	country_code?: string;
	device_type?: string;
	estimated_rate?: number;
	excluded_browser?: string;
	os?: string;
	q_estimated_rate?: number;
	rh_offer_id?: string;
	rh_publisher_id?: string;
	status?: string;

	constructor(data: ICampaignTargeting) {
		this.id = data?.id || 0;
		this.black_hour = data?.black_hour;
		this.browser = data?.browser;
		this.campaign_targeting_links = data?.campaign_targeting_links?.map(link => new CampaignTargetingLinks(link));
		this.country_code = data?.country_code;
		this.device_type = data?.device_type;
		this.estimated_rate = data?.estimated_rate;
		this.os = data?.os;
		this.q_estimated_rate = data?.q_estimated_rate;
		this.rh_offer_id = data?.rh_offer_id;
		this.rh_publisher_id = data?.rh_publisher_id;
		this.status = data?.status;
		this.excluded_browser = data?.excluded_browser;

		makeAutoObservable(this);
	}

	getId() {
		return this.id;
	}

	getBlackHour() {
		return this.black_hour;
	}

	getBrowser() {
		return this.browser;
	}

	getCampaignTargetingLinks() {
		return this.campaign_targeting_links;
	}

	getCountryCode() {
		return this.country_code;
	}

	getDeviceType() {
		return this.device_type;
	}

	getEstimatedRate() {
		return this.estimated_rate;
	}

	getOS() {
		return this.os;
	}

	getQEstimatedRate() {
		return this.q_estimated_rate;
	}

	getExcludedBrowser() {
		return this.excluded_browser;
	}

	getRHOfferId() {
		return this.rh_offer_id;
	}

	getRHPublisherId() {
		return this.rh_publisher_id;
	}

	getStatus() {
		return this.status;
	}

	setId(id: id) {
		this.id = id;
	}

	setBlackHour(blackHour: string) {
		this.black_hour = blackHour;
	}

	setBrowser(browser: string) {
		this.browser = browser;
	}

	setCampaignTargetingLinks(links: CampaignTargetingLinks[]) {
		this.campaign_targeting_links = links;
	}

	setCountryCode(countryCode: string) {
		this.country_code = countryCode;
	}

	setDeviceType(deviceType: string) {
		this.device_type = deviceType;
	}

	setEstimatedRate(estimatedRate: number) {
		this.estimated_rate = estimatedRate;
	}

	setExcludedBrowser(excludedBrowser: string) {
		this.excluded_browser = excludedBrowser;
	}

	setOS(os: string) {
		this.os = os;
	}

	setQEstimatedRate(qEstimatedRate: number) {
		this.q_estimated_rate = qEstimatedRate;
	}

	setRHOfferId(rhOfferId: string) {
		this.rh_offer_id = rhOfferId;
	}

	setRHPublisherId(rhPublisherId: string) {
		this.rh_publisher_id = rhPublisherId;
	}

	setStatus(status: string) {
		this.status = status;
	}
}
