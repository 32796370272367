import { makeObservable, observable } from 'mobx';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions } from '../../../hooks/apis/use-reports-list';
import { PerformanceResponse } from '../../../models/performance.model';
import { PerformanceApi } from '../../../apis/performance.api';
import { BidderCampaignCrudStore } from '../../bidder/campaigns/bidder-campaign-crud.store';

export class BidderCampaignStore {
	crud = new BidderCampaignCrudStore();
	list = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	// performance = new PerformanceStore<CampaignModel>({
	// 	keys: new Set([PerformancesColumnsSnakeCase.CampaignId]),
	// });

	constructor() {
		makeObservable(this, {
			crud: observable,
			list: observable,
		});
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}
}
