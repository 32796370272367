import { IColumnConfig } from '../report.types';
import { DataColumnConfig } from './columns';

export class CreatedByColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'created_by',
			accessor: 'createdBy',
			header: 'Created By',
			...params,
		});
	}
}

export class CreatedAtColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'created_at',
			accessor: 'createdAt',
			header: 'Created At',
			...params,
		});
	}
}

export class UpdatedAtColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'updated_at',
			accessor: 'updatedAt',
			header: 'Updated At',
			...params,
		});
	}
}
