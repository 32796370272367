import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../../modules/routes/index.routes';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IPerformanceOptionsFilterType } from '../../../../modules/hooks/apis/use-performance';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useCampaignHeaders } from '../../../../modules/hooks/theaders/campaigns.headers';
import { IAdminxStore } from '../../../../modules/stores';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	PerformancesColumnsSnakeCase,
	groupByMapper,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

export const CampaignGroupCampaigns = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { campaignGroupId } = useParams();
	const metricsStore = campaignStore.getMetrics();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const reportList = campaignStore.getListStore();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	// if (campaignGroupId) {
	const campaignGroupFilter = {
		column: PerformancesColumnsSnakeCase.CampaignGroupId,
		filterType: IPerformanceOptionsFilterType.Include,
		value: [campaignGroupId as string],
	};
	const { fetchReports } = useReportsList(reportList, {
		groupBys: groupByColumns,
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
		filters: [campaignGroupFilter],
	});
	useReportsList(metricsStore, {
		groupBys: [PerformancesColumnsSnakeCase.Dt],
		reportName: 'adxCampaigns',
		dateSegment: IPerformanceOptionsDateSegments.Daily,
		filters: [campaignGroupFilter],
	});
	// }

	const { columns } = useCampaignHeaders({ includeColumns: { campaign_group: false }, summary: reportList.getData()?.getFooter() });
	const { SelectedActions, FiltersActions } = useCampaignActions();
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Card full>
				<Chart
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				createRoute={campaignGroupId ? `${routes.campaignGroups.createCampaign(campaignGroupId)}` : undefined}
				columns={columns}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaigns_campaign_groups_table')] }}
				fetchReports={fetchReports}
				data={reportList.getData()?.getData() || []}
				defaultSortBy={defaultSortBy}
				isLoading={reportList.getIsLoading()}
				isSummary={true}>
				{() => {
					return {
						FiltersActions,
						SelectedActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
