import { ColumnConfig, MetricsColumnConfig, MetricsCurrencyColumnConfig, MetricsPercentageColumnConfig } from './columns';

export class AuctionsColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'auctions', accessor: 'auctions', header: 'Auctions', ...params });
	}
}

export class WinsColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'wins', accessor: 'wins', header: 'Wins', ...params });
	}
}

export class ClicksColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'clicks', accessor: 'clicks', header: 'Clicks', ...params });
	}
}
export class ImpressionsColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'impressions', accessor: 'impressions', header: 'Impressions', ...params });
	}
}

export class ConversionsColumnConfig<T> extends MetricsColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'conversions', accessor: 'conversions', header: 'Conversions', ...params });
	}
}

export class CostColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'cost', accessor: 'cost', header: 'Cost', ...params });
	}
}

export class ConversionValueColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'conversion_value', accessor: 'conversionValue', header: 'Revenue', ...params });
	}
}

export class WinRateColumnConfig<T> extends MetricsPercentageColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'win_rate', accessor: 'winRate', header: 'Win rate', ...params });
	}
}

export class RoasColumnConfig<T> extends MetricsPercentageColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'roas', accessor: 'roas', header: 'ROAS', ...params });
	}
}

export class CtrColumnConfig<T> extends MetricsPercentageColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'ctr', accessor: 'ctr', header: 'CTR', ...params });
	}
}

export class LpCvrColumnConfig<T> extends MetricsPercentageColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'lp_cvr', accessor: 'lpCvr', header: 'CVR', ...params });
	}
}

export class CpcColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'cpc', accessor: 'cpc', header: 'CPC', ...params });
	}
}

export class CpmColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'cpm', accessor: 'cpm', header: 'CPM', ...params });
	}
}

export class GainColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'gain', accessor: 'gain', header: 'Gain', ...params });
	}
}

export class OptGainColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'opt_gain', accessor: 'optGain', header: 'Opt. Gain', ...params });
	}
}

export class OptCostColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'opt_cost', accessor: 'optCost', header: 'Opt. Cost', ...params });
	}
}

export class AvgBidFloorColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'avg_bid_floor', accessor: 'avgBidFloor', header: 'Avg. bid floor', ...params });
	}
}

export class AvgBidToWinColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'avg_bid_to_win', accessor: 'avgBidToWin', header: 'Avg. bid to win', ...params });
	}
}

export class MaxBidFloorColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'max_bid_floor', accessor: 'maxBidFloor', header: 'Max bid floor', ...params });
	}
}

export class MaxBidToWinColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'max_bid_to_win', accessor: 'maxBidToWin', header: 'Max bid to win', ...params });
	}
}

export class MinBidFloorColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'min_bid_floor', accessor: 'minBidFloor', header: 'Min bid floor', ...params });
	}
}

export class CPAColumnConfig<T> extends MetricsCurrencyColumnConfig<T> {
	constructor(params: Partial<ColumnConfig<T>> = {}) {
		super({ name: 'cpa', accessor: 'cpa', header: 'CPA', ...params });
	}
}

export const generatePerformanceColumns = <T>(): ColumnConfig<T>[] => {
	return [
		new AuctionsColumnConfig<T>(),
		new WinsColumnConfig<T>(),
		new ClicksColumnConfig<T>(),
		new ConversionsColumnConfig<T>(),
		new CostColumnConfig<T>(),
		new ConversionValueColumnConfig<T>(),
		new WinRateColumnConfig<T>(),
		new RoasColumnConfig<T>(),
		new CtrColumnConfig<T>(),
		new LpCvrColumnConfig<T>(),
		new CpcColumnConfig<T>(),
		new CpmColumnConfig<T>(),
		new CPAColumnConfig<T>(),
		new GainColumnConfig<T>(),
		new OptGainColumnConfig<T>(),
		new OptCostColumnConfig<T>(),
		new AvgBidFloorColumnConfig<T>(),
		new AvgBidToWinColumnConfig<T>(),
		new MaxBidFloorColumnConfig<T>(),
		new MaxBidToWinColumnConfig<T>(),
		new MinBidFloorColumnConfig<T>(),
		new ImpressionsColumnConfig<T>(),
	];
};
