import { IDomainCampaignsStatus } from '../../../../models/domain.models';
import { DataColumnConfig } from '../../predefined-columns/columns';
import { CampaignIdColumnConfig, CampaignNameColumnConfig } from '../../predefined-columns/aggregations.columns';
import { FilterType, ReportFilterType, ReportSortOrder } from '../../report.types';
import { TextFilterDefinition } from '../../table-filters/filters.definitions';

export const domainsCampaignsStatusTableConfig = {
	reportParams: {
		reportName: 'adxDomainCampaignsStatus',
		sortBy: 'campaign_name',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new CampaignNameColumnConfig<IDomainCampaignsStatus>(),
		new CampaignIdColumnConfig<IDomainCampaignsStatus>(),
		new DataColumnConfig<IDomainCampaignsStatus>({
			name: 'campaign_status',
			accessor: 'campaignStatus',
			header: 'Status',
			availableFilters: {
				type: FilterType.Select,
				actions: [
					{
						label: 'Equals',
						value: ReportFilterType.EQUALS,
					},
				],
				options: [
					{
						label: 'PAUSED',
						value: 'PAUSED',
					},
					{
						label: 'ACTIVE',
						value: 'ACTIVE',
					},
				],
			},
		}),
		new DataColumnConfig<IDomainCampaignsStatus>({
			name: 'list_type',
			accessor: 'listType',
			header: 'List Type',
			availableFilters: new TextFilterDefinition(),
		}),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsCampaignsStatusTable',
};
