import { PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useParams } from 'react-router-dom';
import styles from './domains-overview.module.scss';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import dayjs from 'dayjs';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { ReportFilterType } from '../../../../modules/components/report-table/report.types';

export const DomainsOverviewPage = observer(() => {
	const { domainsStore } = useStores<IAdminxStore>();
	const { domainName } = useParams();
	const { pretargetingStatusTable, scoreTable, historyTable, campaignStatusTable } = domainsStore;

	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');

	useEffect(() => {
		const defaultFilters = domainName
			? [{ column: 'domain', filterType: ReportFilterType.EQUALS, value: [domainName], inverted: false }]
			: [];
		const reportParams = {
			periodStart: primaryFromDate,
			periodEnd: primaryToDate,
		};
		scoreTable.setDefaultFilters(defaultFilters);
		historyTable.setDefaultFilters(defaultFilters);
		pretargetingStatusTable.setDefaultFilters(defaultFilters);
		campaignStatusTable.setDefaultFilters(defaultFilters);
		scoreTable.fetchReport(reportParams);
		historyTable.fetchReport(reportParams);
		pretargetingStatusTable.fetchReport(reportParams);
		campaignStatusTable.fetchReport(reportParams);
	}, [startDate, endDate, domainName]);

	return (
		<div className={styles.domainOverviewPage}>
			<div className={`${styles.sectionWrapper} ${styles.pretatgetingAndHistoryWrapper}`}>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>Pre-targeting status</PagebarTitle>
					<ReportTable disableCsvExport showTotalRow={false} tableStore={pretargetingStatusTable} />
				</div>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>Black / white list status</PagebarTitle>
					<ReportTable disableCsvExport showTotalRow={false} tableStore={campaignStatusTable} />
				</div>
			</div>

			<div className={styles.sectionWrapper}>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>Scores</PagebarTitle>
					<ReportTable disableCsvExport showTotalRow={false} tableStore={scoreTable} />
				</div>
			</div>

			<div className={styles.sectionWrapper}>
				<div className={styles.tableAndTitle}>
					<PagebarTitle>History</PagebarTitle>
					<ReportTable disableCsvExport showTotalRow={false} tableStore={historyTable} />
				</div>
			</div>
		</div>
	);
});
