import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useCampaignGet } from '../../../../modules/hooks/apis/use-campaign-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IPerformanceOptionsFilterType } from '../../../../modules/hooks/apis/use-performance';
import { IAdminxStore } from '../../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useWithPerformanceHeaders } from '../../../../modules/hooks/theaders/with-performance.headers';
import { useEffect } from 'react';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	PerformancesColumnsSnakeCase,
	groupByMapper,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

export const CampaignDailyReport = observer(() => {
	const { campaignStore, settingsStore } = useStores<IAdminxStore>();
	const reportList = campaignStore.getListStore();
	const metricsStore = campaignStore.getMetrics();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();

	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				date: true,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				ctr: true,
				lpCvr: true,
				impressions: false,
				optCost: false,
				optGain: false,
				gain: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	const { campaignId } = useParams();
	if (campaignId) {
		useCampaignGet({ id: campaignId });
		const campaignFilter = [
			{
				column: PerformancesColumnsSnakeCase.Id,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [campaignId],
			},
		];
		useReportsList(reportList, {
			groupBys: [...groupByColumns, PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
			reportName: 'adxCampaigns',
			sortBy: IPerformanceOptionsSortBy.Id,
			dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
				? IPerformanceOptionsDateSegments.Daily
				: IPerformanceOptionsDateSegments.None,
			filters: campaignFilter,
		});
		useReportsList(metricsStore, {
			groupBys: [PerformancesColumnsSnakeCase.Dt],
			reportName: 'adxCampaigns',
			dateSegment: IPerformanceOptionsDateSegments.Daily,
			filters: campaignFilter,
		});
	}

	const { columns } = useWithPerformanceHeaders({
		summaryItem: reportList.getData()?.getFooter(),
	});

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Daily Report</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}>
				<Chart
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				columns={columns}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'daily_report_campaign_table')] }}
				data={reportList.getData()?.getData() || []}
				isLoading={reportList.getIsLoading()}
				isSummary={true}
			/>
		</Page>
	);
	return null;
});
