import { IDomainHistory } from '../../../../models/domain.models';
import { DataColumnConfig } from '../../predefined-columns/columns';
import { CreatedAtColumnConfig, CreatedByColumnConfig } from '../../predefined-columns/data.columns';
import { FilterType, ReportFilterType, ReportSortOrder } from '../../report.types';
import { TextFilterDefinition } from '../../table-filters/filters.definitions';

export const domainHistoryTableConfig = {
	reportParams: {
		reportName: 'adxDomainHistory',
		sortBy: 'created_at',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new DataColumnConfig<IDomainHistory>({
			name: 'id',
			accessor: 'id',
			header: 'ID',
		}),
		new DataColumnConfig<IDomainHistory>({
			name: 'domain_status',
			accessor: 'domainStatus',
			header: 'Status',
			availableFilters: {
				type: FilterType.Select,
				actions: [
					{
						label: 'Equals',
						value: ReportFilterType.EQUALS,
					},
				],
				options: [
					{
						label: 'BLOCKED',
						value: 'BLOCKED',
					},
					{
						label: 'NEW',
						value: 'NEW',
					},
				],
			},
		}),
		new DataColumnConfig<IDomainHistory>({
			name: 'description',
			accessor: 'description',
			header: 'Description',
			availableFilters: new TextFilterDefinition(),
		}),
		new DataColumnConfig<IDomainHistory>({
			name: 'billing_name',
			accessor: 'billingName',
			header: 'Billing Name',
			availableFilters: new TextFilterDefinition(),
		}),
		new DataColumnConfig<IDomainHistory>({
			name: 'billing_id',
			accessor: 'billingId',
			header: 'Billing ID',
			availableFilters: new TextFilterDefinition(),
		}),
		new CreatedByColumnConfig<IDomainHistory>(),
		new CreatedAtColumnConfig<IDomainHistory>(),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsHistoryTable',
};
