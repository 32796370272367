import {
	AdvertiserIdAdxColumnConfig,
	AdvertiserNameAdxColumnConfig,
	AuctionTypeAdxColumnConfig,
	BidKeyAdxColumnConfig,
	BrowserNameAdxColumnConfig,
	CampaignGroupIdAdxColumnConfig,
	CampaignGroupNameAdxColumnConfig,
	CampaignIdAdxColumnConfig,
	CampaignNameAdxColumnConfig,
	CityAdxColumnConfig,
	CreativeBrandAdxColumnConfig,
	CreativeGroupDesignAdxColumnConfig,
	CreativeGroupIdAdxColumnConfig,
	CreativeIdAdxColumnConfig,
	CreativeSizeAdxColumnConfig,
	DateAdxColumnConfig,
	DomainAdxColumnConfig,
	GeoAdxColumnConfig,
	OsNameAdxColumnConfig,
	PageAdxColumnConfig,
	PreferredDealIdAdxColumnConfig,
	PreferredDealNameAdxColumnConfig,
	PublisherIdAdxColumnConfig,
	StateAdxColumnConfig,
	StrategyIdAdxColumnConfig,
	TestGroupNameAdxColumnConfig,
	TestIdAdxColumnConfig,
	TestNameAdxColumnConfig,
} from '../../predefined-columns/adx.columns';
import { generatePerformanceColumns } from '../../predefined-columns/metrics.columns';
import { IReportTableStoreParams } from '../../report-table.store';
import { ReportSortOrder } from '../../report.types';

interface ReportMetrics {
	auctions: number;
	avgBidFloor: number;
	avgBidToWin: number;
	clicks: number;
	conversions: number;
	cost: number;
	cpc: number;
	cpm: number;
	ctr: number;
	impressions: number;
	maxBidFloor: number;
	maxBidToWin: number;
	minBidFloor: number;
	minBidToWin: number;
	optCost: number;
	winRate: number;
	wins: number;
	totalBudgetLimit: number;
	conversionValue: number;
	roas: number;
	lpCvr: number;
	optGain: number;
	gain: number;
	cpa: number;
	bid: string;
}

export interface DynamicReport extends ReportMetrics {
	date: string;
	campaignId: number;
	campaign: string;
	campaignGroupId: number;
	campaignGroup: string;
	advertiserId: number;
	advertiser: string;
	creativeId: number;
	testId: number;
	page: string;
	publisherId: string;
	browserName: string;
	osName: string;
	geo: string;
	state: string;
	city: string;
	auctionType: string;
	creativeBrandName: string;
	creativeSize: string;
	creativeGroupDesign: string;
	preferredDealId: string;
	testName: string;
	preferredDealName: string;
	testGroupName: string;
	domain: string;
	creativeGroupId: number; // ?
	bidKey: string; // ?
	bidIsEditable: boolean; // ?
	bidKeyId: number; // ?
	creativeImageUrl: string; //?
}

export const dynamicReportTableConfig: IReportTableStoreParams<DynamicReport> = {
	reportParams: {
		reportName: 'adx',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new DateAdxColumnConfig<DynamicReport>(),
		new CampaignNameAdxColumnConfig<DynamicReport>(),
		new CampaignIdAdxColumnConfig<DynamicReport>(),
		new AdvertiserNameAdxColumnConfig<DynamicReport>(),
		new AdvertiserIdAdxColumnConfig<DynamicReport>(),
		new CampaignGroupNameAdxColumnConfig<DynamicReport>(),
		new CampaignGroupIdAdxColumnConfig<DynamicReport>(),
		new CreativeGroupDesignAdxColumnConfig<DynamicReport>(),
		new CreativeGroupIdAdxColumnConfig<DynamicReport>(),
		new CreativeBrandAdxColumnConfig<DynamicReport>(),
		new CreativeIdAdxColumnConfig<DynamicReport>(),
		new StrategyIdAdxColumnConfig<DynamicReport>(),
		new CreativeSizeAdxColumnConfig<DynamicReport>(),
		new TestIdAdxColumnConfig<DynamicReport>(),
		new TestNameAdxColumnConfig<DynamicReport>(),
		new TestGroupNameAdxColumnConfig<DynamicReport>(),
		new DomainAdxColumnConfig<DynamicReport>(),
		new PreferredDealIdAdxColumnConfig<DynamicReport>(),
		new PreferredDealNameAdxColumnConfig<DynamicReport>(),
		new PublisherIdAdxColumnConfig<DynamicReport>(),
		new BrowserNameAdxColumnConfig<DynamicReport>(),
		new OsNameAdxColumnConfig<DynamicReport>(),
		new GeoAdxColumnConfig<DynamicReport>(),
		new StateAdxColumnConfig<DynamicReport>(),
		new CityAdxColumnConfig<DynamicReport>(),
		new AuctionTypeAdxColumnConfig<DynamicReport>(),
		new BidKeyAdxColumnConfig<DynamicReport>(),
		new PageAdxColumnConfig<DynamicReport>(),

		...generatePerformanceColumns<DynamicReport>(),
	],
	isGraphAvailable: true,
	tableName: 'dynamic-report',
};
