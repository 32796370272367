import { makeObservable, observable } from 'mobx';
import { CampaignInputOptionsStore } from './campaign-input-options.store';
import { CampaignCrudStore } from './campaign-crud.store';
import { IPerformanceOptions } from '../../hooks/apis/use-reports-list';
import { FilterStore, FiltersMap } from '@monorepo/controlled/src/stores/filter.store';
import { campaignFilterPrototypes, filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { PerformanceResponse } from '../../models/performance.model';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { PerformanceApi } from '../../apis/performance.api';
import { Statuses } from '../../enums/status';
import { StringActions } from '@monorepo/tools/src/lib/utils/string';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';

// every enum here is the path withtout / and ids
// for example, advertiser/1/campaigns will transform to CampaignFiltersStoreKeys.Advertiser
export enum CampaignFiltersStoreKeys {
	Campaigns = 'campaigns',
	Advertiser = 'advertiserscampaigns',
	CampaignGroup = 'campaigngroupscampaigns',
	// CampaignsDailyReport = 'campaignsdailyreport',
}

export class CampaignStore {
	crud = new CampaignCrudStore();
	list = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	metrics = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	inputOptions = new CampaignInputOptionsStore();

	settingsStore: SettingsStore;

	// performance = new PerformanceStore<CampaignModel>({
	// 	keys: new Set([PerformancesColumnsSnakeCase.CampaignId]),
	// });

	constructor(settingsStore: SettingsStore) {
		makeObservable(this, {
			crud: observable,
			list: observable,
			// performance: observable,
			metrics: observable,
		});
		this.settingsStore = settingsStore;

		this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const prototype = campaignFilterPrototypes.find(filter => filter.MenuComponent === 'Status');
		let defaultFilters: FiltersMap;
		if (prototype) {
			defaultFilters = new Map([
				[
					1,
					new FilterModel({
						index: 1,
						label: `Status is ${Statuses.Active}`,
						action: StringActions.Equals,
						value: [Statuses.Active],
						prototype,
					}),
				],
			]);
		}

		Object.values(CampaignFiltersStoreKeys).map((key: CampaignFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const campaignFilterByKey = new FilterStore({
				key,
				currentFilters: defaultFilters,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, campaignFilterByKey);
		});
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getInputOptions() {
		return this.inputOptions;
	}

	// getPerformance() {
	// 	return this.performance;
	// }

	getMetrics() {
		return this.metrics;
	}
}
