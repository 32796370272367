import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import dayjs from 'dayjs';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Card } from '@monorepo/base/src/components/card/card';
import ReportTable from '../../../modules/components/report-table/table-component';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';

export const Reports: FC = observer(() => {
	const { reportStore } = useStores<IAdminxStore>();
	const { dynamicReport } = reportStore;
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');

	useEffect(() => {
		const reportParams = {
			periodStart: primaryFromDate,
			periodEnd: primaryToDate,
		};
		dynamicReport.fetchReport(reportParams);
	}, [startDate, endDate]);

	const { metrics, xLabels } = useMetrics(dynamicReport.reportStore.graph?.getData() ?? null);

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Dynamic Report</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart
					isLoading={dynamicReport.reportStore.graph?.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<ReportTable tableStore={dynamicReport} />
			<Outlet />
		</Page>
	);
});
