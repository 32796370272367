import { FilterType, IColumnConfig, ReportFilterType } from '../report.types';
import { NumbersFilterDefinition, TextFilterDefinition } from '../table-filters/filters.definitions';
import { DataColumnConfig } from './columns';

export class DomainScoreColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'score',
			accessor: 'score',
			header: 'Score',
			visible: false,
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class DomainFormatColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'format',
			accessor: 'format',
			header: 'Format',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DomainScorerClassColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'scorer_class',
			accessor: 'scorerClass',
			header: 'Scorer Class',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DomainSalesColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'sales',
			accessor: 'sales',
			header: 'Sales',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class DomainPretargetingIdColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'id',
			accessor: 'id',
			header: 'ID',
			...params,
		});
	}
}

export class DomainBillingIdColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'billing_id',
			accessor: 'billingId',
			header: 'Billing ID',
			...params,
		});
	}
}

export class DomainTypeColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'type',
			accessor: 'type',
			header: 'Type',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DomainPretargetingModeColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'mode',
			accessor: 'mode',
			header: 'Mode',
			availableFilters: {
				type: FilterType.Select,
				actions: [
					{
						label: 'Include',
						value: ReportFilterType.INCLUDE,
					},
				],
				options: [
					{
						label: 'INCLUSIVE',
						value: 'INCLUSIVE',
					},
					{
						label: 'EXCLUSIVE',
						value: 'EXCLUSIVE',
					},
				],
			},
			...params,
		});
	}
}

export class DomainNameColumnConfig<T> extends DataColumnConfig<T> {
	constructor(params: Partial<IColumnConfig<T>> = {}) {
		super({
			name: 'name',
			accessor: 'name',
			header: 'Name',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}
